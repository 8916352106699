import React, { useEffect, useState } from "react";

import config from "../../../../../config";
import { useApi } from "../../../../../api";
import Invoice from "./Invoice";
import Dialog from "./Dialog";
import useI18nSnackbar from "../../../../../hooks/useI18nSnackbar";

const InvoiceContainer = ({ invoiceId, onClose, onUpdate }) => {
  const api = useApi();
  const { enqueueSnackbar } = useI18nSnackbar();

  const [invoice, setInvoice] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [invoiceStatus, setInvoiceStatus] = useState(null);

  useEffect(() => {
    setLoading(true);

    api.get(`/v2/invoices/${invoiceId}`).then(response => {
      setInvoice(response.data);
      setLoading(false);
    });
  }, [api, invoiceId]);

  useEffect(() => {
    setInvoiceStatus(invoice.status);
  }, [invoice]);

  const handleExport = () => {
    const location = `${config.api.baseUrl}/v2/invoices/${invoice.id}/export`;
    window.location.href = location;
  };

  const handleUpdateStatus = status => {
    setInvoiceStatus(status);
    api.put(`/v2/invoices/${invoiceId}/status`, { status }).then(() => {
      onUpdate();

      enqueueSnackbar("system.billing.responses.statusUpdated", {
        variant: "success"
      });
    });
  };

  if (isLoading) {
    return null;
  }

  return (
    <Dialog onClose={onClose} invoiceId={invoiceId}>
      <Invoice
        invoice={invoice}
        invoiceStatus={invoiceStatus}
        onUpdateStatus={handleUpdateStatus}
        onClose={onClose}
        onExport={handleExport}
      />
    </Dialog>
  );
};

export default InvoiceContainer;
