import React from "react";
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Box,
  Button,
  FormControlLabel,
  Switch,
  TableFooter
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";

import I18nText from "../../../../../elements/I18nText";
import FormattedDate from "../../../../../elements/FormattedDate";
import Section from "../../../../../elements/Section";
import SectionHeader from "../../../../../elements/SectionHeader";
import DescriptionList from "../../../../../elements/DescriptionList";
import { FORMAT_DATE } from "../../../../../utils/formatting";
import FormattedNumber from "../../../../../elements/FormattedNumber";
import {
  ButtonsWrapper,
  SecondaryButton
} from "../../../../../elements/Button";
import TableFooterCell from "../../../../../elements/TableFooterCell";

const renderAmount = amount => {
  if (amount === null) {
    return "";
  }

  return (
    <>
      <FormattedNumber decimals={2}>{amount}</FormattedNumber> €
    </>
  );
};

const renderName = item => {
  if (item.name) {
    return item.name;
  }

  return <I18nText>{`billing.aliases.${item.type}`}</I18nText>;
};

const renderPosition = item => {
  const name = renderName(item);

  if (!item.deviceInfo) {
    return name;
  }

  const device = item.deviceInfo;

  return (
    <>
      <Box>{name}</Box>
      <Box>
        #{device.id} ({device.user.username})
      </Box>
      <Box>
        {device.address.street} {device.address.streetNumber},{" "}
        {device.address.countryCode}-{device.address.postalCode}{" "}
        {device.address.city}
      </Box>
    </>
  );
};

const Invoice = React.memo(
  ({ invoice, invoiceStatus, onUpdateStatus, onExport, onClose }) => {
    const options = {
      metadata: [
        {
          label: "billing.common.debtor",
          value: invoice.debtorIdent
        },
        {
          label: "billing.common.billedAt",
          value: (
            <FormattedDate format={FORMAT_DATE}>
              {invoice.billedAt}
            </FormattedDate>
          )
        }
      ],
      actions: [
        {
          label: "system.billing.actions.export",
          value: (
            <Button
              onClick={onExport}
              variant="contained"
              color="primary"
              size="small"
              startIcon={<CloudDownloadIcon />}
            >
              <I18nText>system.billing.csvExport</I18nText>
            </Button>
          )
        },
        {
          label: "billing.common.isHandled",
          value: (
            <FormControlLabel
              control={
                <Switch
                  checked={invoiceStatus === "handled"}
                  onChange={e =>
                    onUpdateStatus(e.target.checked ? "handled" : "pending")
                  }
                  name="status"
                />
              }
            />
          )
        }
      ]
    };

    return (
      <>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Section>
              <SectionHeader>
                <I18nText>system.billing.sections.metadata</I18nText>
              </SectionHeader>
              <DescriptionList options={options.metadata} />
            </Section>
          </Grid>

          <Grid item xs={12} md={6}>
            <Section>
              <SectionHeader>
                <I18nText>system.billing.sections.actions</I18nText>
              </SectionHeader>
              <DescriptionList options={options.actions} />
            </Section>
          </Grid>
        </Grid>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>
                <I18nText>billing.common.invoiceItem.sequence</I18nText>
              </TableCell>
              <TableCell>
                <I18nText>billing.common.invoiceItem.position</I18nText>
              </TableCell>
              <TableCell align="center">
                <I18nText>billing.common.invoiceItem.period</I18nText>
              </TableCell>
              <TableCell align="right">
                <I18nText>billing.common.invoiceItem.price</I18nText>
              </TableCell>
              <TableCell align="right">
                <I18nText>billing.common.invoiceItem.quantity</I18nText>
              </TableCell>
              <TableCell align="right">
                <I18nText>billing.common.invoiceItem.totalPrice</I18nText>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {invoice.items.map(item => (
              <TableRow key={item.id}>
                <TableCell>{item.sequence}</TableCell>
                <TableCell>{renderPosition(item)}</TableCell>
                <TableCell align="center">
                  <FormattedDate format={FORMAT_DATE}>
                    {item.periodBegin}
                  </FormattedDate>{" "}
                  -{" "}
                  <FormattedDate format={FORMAT_DATE}>
                    {item.periodEnd}
                  </FormattedDate>
                </TableCell>
                <TableCell align="right">{renderAmount(item.price)}</TableCell>
                <TableCell align="right">{item.quantity}</TableCell>
                <TableCell align="right">
                  {renderAmount(item.totalPrice)}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TableFooterCell colSpan={5} align="right">
                <I18nText>system.billing.invoice.footer.totalPrice</I18nText>
              </TableFooterCell>
              <TableFooterCell align="right">
                {renderAmount(invoice.totalPrice)}
              </TableFooterCell>
            </TableRow>
            <TableRow>
              <TableFooterCell colSpan={5} align="right">
                <I18nText>
                  system.billing.invoice.footer.totalVatAmount
                </I18nText>
              </TableFooterCell>
              <TableFooterCell align="right">
                {renderAmount(invoice.totalVatAmount)}
              </TableFooterCell>
            </TableRow>
            <TableRow>
              <TableFooterCell colSpan={5} align="right">
                <I18nText>
                  system.billing.invoice.footer.totalPriceGross
                </I18nText>
              </TableFooterCell>
              <TableFooterCell align="right">
                {renderAmount(invoice.totalPriceGross)}
              </TableFooterCell>
            </TableRow>
          </TableFooter>
        </Table>

        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box marginTop={2}>
              <ButtonsWrapper
                rightButton={
                  <SecondaryButton onClick={onClose} icon={<CloseIcon />}>
                    <I18nText>actions.close</I18nText>
                  </SecondaryButton>
                }
              />
            </Box>
          </Grid>
        </Grid>
      </>
    );
  }
);

export default Invoice;
