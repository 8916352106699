import React, { useMemo } from "react";
import { Box, LinearProgress } from "@material-ui/core";
import { styled } from "@material-ui/core/styles";

const LoadingOverlay = React.memo(({ isLoading, minHeight, children }) => {
  const OverlayContainer = useMemo(
    () =>
      styled(Box)({
        position: "relative",
        minHeight
      }),
    [minHeight]
  );

  const ProgressContainer = styled(Box)({
    position: "absolute",
    transform: "translateX(50%)",
    top: 100,
    width: "50%",
    zIndex: 1601
  });

  const OverlayContent = styled(Box)({
    position: "absolute",
    top: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(255,255,255,.75)",
    opacity: 0,
    transition: "opacity 0.3s",
    zIndex: 1600
  });

  return (
    <OverlayContainer>
      {isLoading && (
        <>
          <OverlayContent style={{ opacity: isLoading ? 0.8 : 0 }} />
          <ProgressContainer>
            <LinearProgress color="secondary" />
          </ProgressContainer>
        </>
      )}
      {children}
    </OverlayContainer>
  );
});

export default LoadingOverlay;
