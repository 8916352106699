import React, { useState, useCallback, useEffect } from "react";
import { format } from "date-fns";
import { useHistory } from "react-router-dom";

import MetadataForm from "./MetadataForm";
import Table from "./Table/Table";
import { useApi } from "../../../api";
import Pagination from "../../../elements/Pagination";
import { PERMISSIONS } from "../../../auth";
import Layout from "./Layout";
import { useTranslation } from "react-i18next";
import { createFieldErrorMessage } from "../../../utils/formErrors";

const FormContainer = ({
  initialValues,
  preSelectedDeviceIds = [],
  isSubmitting,
  onSubmit,
  onClose
}) => {
  const api = useApi();
  const history = useHistory();
  const { t } = useTranslation();
  const [devices, setDevices] = useState([]);
  const [selectedDeviceIds, setSelectedDeviceIds] = useState(
    preSelectedDeviceIds
  );
  const [totalCount, setTotalCount] = useState(null);
  const [page, setPage] = useState(1);
  const perPage = 50;
  const [isUpdating, setUpdating] = useState(false);
  const [errors, setErrors] = useState({});

  const [values, setValues] = useState(initialValues);

  useEffect(() => {
    let mounted = true;
    let query = `type=sms&permission=${PERMISSIONS.REF_DATE_QUERY}&per_page=${perPage}`;

    if (page) {
      query += `&page=${page}`;
    }

    setUpdating(true);

    api.get(`/v2/devices?${query}`).then(response => {
      if (!mounted) {
        return;
      }

      setDevices(response.data);
      setTotalCount(response.headers["x-total-count"]);
      setUpdating(false);
    });

    return () => {
      mounted = false;
    };
  }, [api, page]);

  const validate = () => {
    const errors = {};

    if (
      values.intervalType === "monthly" &&
      values.monthlyInterval.length === 0
    ) {
      errors.monthlyInterval = createFieldErrorMessage(
        "monthlyInterval",
        "required"
      );
    }

    if (values.intervalType === "daily" && values.dailyIntervals.length === 0) {
      errors.dailyIntervals = createFieldErrorMessage(
        "dailyIntervals",
        "required"
      );
    }

    if (
      values.intervalType === "intraday" &&
      values.intradayInterval.length === 0
    ) {
      errors.intradayInterval = createFieldErrorMessage(
        "intradayInterval",
        "required"
      );
    }

    setErrors(errors);

    return Object.keys(errors).length === 0;
  };

  const handleSubmit = () => {
    if (!validate()) {
      return;
    }

    let intervals = [];
    if (values.intervalType === "monthly") {
      intervals = [values.monthlyInterval];
    } else if (values.intervalType === "daily") {
      intervals = values.dailyIntervals;
    } else if (values.intervalType === "intraday") {
      intervals = [values.intradayInterval];
    }

    onSubmit({
      id: values.id,
      active: values.isActive,
      intervals,
      queryAt: format(new Date(values.queryAt), "HH:mm"),
      olderThan: values.olderThan,
      deviceIds: selectedDeviceIds
    });
  };

  const dailyIntervalOptions = () => {
    const dailyValues = [
      "daily_1",
      "daily_2",
      "daily_3",
      "daily_4",
      "daily_5",
      "daily_6",
      "daily_0"
    ];

    const options = [];
    dailyValues.forEach(value => {
      options.push({
        name: t(`refDateQueries.intervals.${value}`),
        value
      });
    });

    return options;
  };

  const handleSetValue = (key, value) => {
    setValues(state => {
      state[key] = value;

      setValues({ ...state });
    });
  };

  const handleToggleDevice = useCallback(deviceId => {
    const id = parseInt(deviceId, 10);

    setSelectedDeviceIds(prev => {
      const idx = prev.indexOf(id);

      if (idx === -1) {
        return [...prev, id];
      }

      const newState = [...prev];
      newState.splice(idx, 1);

      return newState;
    });
  }, []);

  return (
    <Layout
      onClose={onClose}
      isUpdating={isUpdating}
      isSubmitting={isSubmitting}
      selectedDeviceIds={selectedDeviceIds}
      onSubmit={handleSubmit}
      metadataComponent={
        <MetadataForm
          values={values}
          setValue={handleSetValue}
          dailyIntervalOptions={dailyIntervalOptions()}
          onClose={() => history.push("/devices/ref-date-queries")}
          errors={errors}
        />
      }
      paginationComponent={
        <Pagination
          totalCount={totalCount}
          perPage={perPage}
          page={page}
          onSelectPage={page => setPage(page)}
          allowPerPageSelect={false}
        />
      }
      tableComponent={
        <Table
          devices={devices}
          selectedDeviceIds={selectedDeviceIds}
          onToggleDevice={handleToggleDevice}
          isUpdating={isUpdating}
        />
      }
    />
  );
};

export default FormContainer;
