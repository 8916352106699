import React from "react";
import { Grid, Box, makeStyles } from "@material-ui/core";

import Section from "../../../elements/Section";
import SectionHeader from "../../../elements/SectionHeader";
import ProbeTable from "./ProbeTable/ProbeTable";
import Alarm from "./Alarm";
import Threshold from "./Threshold";
import Chart from "./Chart";
import DescriptionList from "../../../elements/DescriptionList";
import CounterTable from "./CounterTable/CounterTable";
import I18nText from "../../../elements/I18nText";
import { nl2br } from "../../../utils/stringUtils";

const useStyles = makeStyles(theme => ({
  events: {
    "& > *:not(:first-child)": {
      marginTop: theme.spacing(1)
    }
  }
}));

const Status = ({ probes, counters, listOptions, device }) => {
  const classes = useStyles();

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Section>
            <Chart device={device} />
          </Section>
        </Grid>

        {(device.thresholdStatus || device.alarms.length > 0) && (
          <Grid item xs={12}>
            <Section>
              <SectionHeader>
                <I18nText>devices.status.sections.events</I18nText>
              </SectionHeader>
              <Box className={classes.events}>
                {device.alarms.map(alarm => {
                  return <Alarm key={alarm.id} alarm={alarm} />;
                })}

                {device.thresholdStatus && (
                  <Threshold
                    deviceId={device.id}
                    thresholdSensor={device.thresholdSensor}
                    confirmationStatus={device.thresholdConfirmationStatus}
                  />
                )}
              </Box>
            </Section>
          </Grid>
        )}

        {probes.length > 0 && (
          <Grid item xs={12}>
            <Section>
              <SectionHeader>
                <I18nText>devices.status.sections.probeTable</I18nText>
              </SectionHeader>
              <ProbeTable sensors={probes} />
            </Section>
          </Grid>
        )}

        {counters.length > 0 && (
          <Grid item xs={12}>
            <Section>
              <SectionHeader>
                <I18nText>devices.status.sections.counterTable</I18nText>
              </SectionHeader>
              <CounterTable sensors={counters} />
            </Section>
          </Grid>
        )}

        <Grid item xs={12} md={6}>
          <Section>
            <SectionHeader>
              <I18nText>devices.status.sections.device</I18nText>
            </SectionHeader>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <DescriptionList options={listOptions} />
              </Grid>
            </Grid>
          </Section>
        </Grid>

        <Grid item xs={12} md={6}>
          <Section>
            <SectionHeader>
              <I18nText>devices.status.sections.comment</I18nText>
            </SectionHeader>
            {nl2br(device.comment)}
          </Section>
        </Grid>
      </Grid>
    </>
  );
};

export default Status;
